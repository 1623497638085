@tailwind base;
@tailwind components;
@tailwind utilities;

.single-col {
  -webkit-column-width: 250px;
  column-width: 250px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

@media screen and (max-height: 980px), screen and (min-width: 1000px) {
  body {
    overflow: auto;
    background-color: black;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
